import React from 'react';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';

export default function PriorityIcon({ is_top = false }) {
    if (!is_top) {
        return null;
    }
    return (
        <DarkTooltip className="o-6" content="Top Priority Issues" position="top">
            <CustomIcon icon={CustomIconName.STAR} />
        </DarkTooltip>
    );
}
