import { makeAutoObservable, observable, runInAction } from 'mobx';

import api from 'api';
import getAIText from 'api/getAIText';
import { CURRENT_USER_ID, CURRENT_USER_IS_ADMIN, IS_IFRAME, IS_VOTING_BOARD } from 'constants/global';
import { failRequest, logEvent, sendToSentry } from 'utils';

import { Idea } from 'store/models/Idea';
import { issuesList } from 'store/models/IssuesList';

import { ANNOUNCE_STATUS, UNSAVED_MODEL_ID } from 'utils/consts';
import copyToClipboard from 'utils/copyToClipboard';
import toUrl from 'utils/toUrl';
import { uuidv4 } from 'utils/uuidv4';

import { toast } from 'components/Toast';

import { mainStore } from './MainStore';
import { fillWithInterval } from './utils/fillWithInterval';
import { utilsStore } from './UtilsStore';

/**
 * Idea Announce
 */
export default class Announce {
    uuid = null;
    /**
     * @type {number}
     */
    id = UNSAVED_MODEL_ID;

    /**
     * @type {string}
     */
    name = '';

    /**
     * @type {string}
     */
    aiName = '';

    /**
     * @type {string}
     */
    description = '';

    /**
     * @type {string}
     */
    aiDescription = '';

    /**
     * @type {boolean}
     */
    is_active = false;

    /**
     * @type {number}
     */
    idea_id = 0;

    /**
     * @type {number}
     */
    board_id = 0;

    /**
     * @type {null|number}
     */
    release_note_item_id = null;

    /**
     * @type {string}
     */
    release_date = '';

    /**
     * @type {string}
     */
    slug = '';

    /**
     * @type {string}
     */
    url = '';

    /**
     * @type {string}
     */
    text_status = '';

    /**
     * @type {boolean}
     */
    hide_reactions = false;

    /**
     * @type {Array<Object>}
     */
    labels = [];

    /**
     * @type {Array<Object>}
     */
    votingAnnouncesReactions = [];

    /**
     * @type {string}
     */
    created = '';

    /**
     * @type {string}
     */
    updated = '';

    /**
     * @type {Object|null}
     */
    history = observable.box(null);

    /**
     * @type {boolean}
     */
    is_ai_last_edit = false;

    /**
     * @type {Object|null}
     */
    lastEditor = null;

    /**
     * @type {number|null}
     */
    author_id = null;

    /**
     * @type {Idea|null}
     */
    rawIdea = null;

    /**
     * @type {boolean}
     */
    unread = false;

    /**
     * @param {Object} data
     */
    constructor(data) {
        this.updateModel(data);

        this.uuid = uuidv4();

        makeAutoObservable(this);
    }

    get labelsIds() {
        return this.labels.map((el) => el.id);
    }

    get labelsList() {
        const list = this.board?.ideaLabels ?? [];
        return list.filter((label) => this.labelsIds.includes(label.id));
    }

    get isPublished() {
        const isDoneIdea = !this.idea_id || this.idea.isDone;
        return this.is_active && isDoneIdea;
    }

    /**
     * Updates the model with the new data.
     *
     * @param {Object} data - The new data.
     */
    updateModel(data) {
        // eslint-disable-next-line no-unused-vars
        const { idea, author, history, ...otherData } = data;
        Object.assign(this, otherData);

        this.rawIdea = idea
            ? new Idea({ ...idea, boardId: mainStore.activeBoard.id, votingAnnounce: this })
            : this.rawIdea;

        // Ensure this.history is an observable box
        if (!this.history) {
            this.history = observable.box(null);
        }

        // If data.history exists and is an object, set it
        if (history && typeof history === 'object') {
            this.history.set(history);
        }
    }

    /**
     * Get plain text description without HTML tags.
     *
     * @returns {string} - The plain text description.
     */
    get plainDescription() {
        return this.description?.replace(/<\/?[^>]+(>|$)/g, '') ?? '';
    }

    /**
     * Returns the corresponding Idea
     */
    get idea() {
        if (IS_VOTING_BOARD) return this.rawIdea;

        return issuesList.getIdeaById(this.idea_id);
    }

    get board() {
        return this.idea?.board || mainStore.activeBoard;
    }

    /**
     * Returns the last author from history
     */
    get author() {
        if (!this.author_id) return null;
        return mainStore.users.usersIds.get(this.author_id) || null;
    }

    get updater() {
        if (!this.historyObject) return null;
        const history = Object.values(this.historyObject);
        history.sort((a, b) => (a.created > b.created ? -1 : 1));
        return mainStore.users.usersIds.get(history[0]?.user_id) || null;
    }

    get historyObject() {
        const history = this.history.get();
        if (!history) return null;
        return history;
    }

    get apiEndpoint() {
        return `${this.board.apiEndpoint}/announces/${this.id}`;
    }

    get isEmpty() {
        return !this.name && !this.description;
    }

    fillWithInterval = fillWithInterval;

    setAIText = (description) => {
        if (this.aiDescription === '') {
            this.aiDescription = description.replace(/^>/, '');
        } else {
            this.aiDescription = this.aiDescription + description;
        }
    };

    clearAIText = () => {
        this.aiDescription = '';
        this.aiName = '';
    };

    downCounter() {
        if (!IS_IFRAME || !this.unread) return;
        try {
            window.parent.postMessage('minus1', '*');
        } catch (error) {}
    }

    updateReaction({ reaction, remove = false }) {
        if (remove) {
            this.votingAnnouncesReactions = this.votingAnnouncesReactions.filter(
                (el) => !(el.reaction === reaction && el.voting_user_id === mainStore.currentUser.id),
            );
        } else {
            this.votingAnnouncesReactions.push({ reaction: reaction, voting_user_id: mainStore.currentUser.id });
        }
    }

    userAlreadySetReaction(reaction) {
        return this.votingAnnouncesReactions.some(
            (el) => el.reaction === reaction && el.voting_user_id === mainStore.currentUser.id,
        );
    }

    copyLink = () => {
        logEvent('Copy Announcement link');
        copyToClipboard(this.url);
        toast({ description: 'Copied to clipboard', duration: 1000 });
    };

    /**
     * Update local and remote announce
     */
    async update(announce) {
        const requestData = toUrl(announce);

        try {
            this.updateModel(announce);
            const { data } = await api.put(this.apiEndpoint, requestData);
            this.updateModel(data);
        } catch (e) {
            failRequest(e);
        }
    }

    async generateAIDescription(abortController) {
        if (abortController?.signal?.aborted) return;

        const url = `/rest${this.board.apiEndpoint}/announces/chatgpt-description?idea_id=${this.idea_id}`;
        this.clearAIText();
        await getAIText(url, this.setAIText, abortController);
    }

    async getNameByAI(abortController, sendDescription) {
        try {
            const requestData = sendDescription ? toUrl({ description: this.aiDescription }) : undefined;
            const { data } = await api.post(`${this.board.apiEndpoint}/announces/chatgpt-title`, requestData, {
                signal: abortController.signal,
                params: {
                    idea_id: this.idea_id,
                },
            });
            if (data !== this.aiName) {
                this.fillWithInterval(data, 'aiName', abortController);
            }
        } catch (e) {
            failRequest(e);
        }
    }
    async remove() {
        try {
            await api.delete(this.apiEndpoint);
        } catch (e) {
            failRequest(e);
        }
    }

    async readIt() {
        if (CURRENT_USER_IS_ADMIN) return;
        try {
            this.downCounter();
            await api.post(`${this.apiEndpoint}/mark-read?source=board`);

            runInAction(() => {
                this.unread = false;
            });

            await this.board.getUnreadCount();
        } catch (e) {
            sendToSentry('Fail read Idea', { error: e });
        }
    }

    async clear() {
        logEvent('Clear Announce Click - confirm');
        await this.update({ name: '', description: '', text_status: ANNOUNCE_STATUS.DRAFT });
    }

    async setReaction(reaction) {
        if (!CURRENT_USER_ID) {
            utilsStore.setHistoryAction({ reaction: { reaction, id: this.idea_id } });
            return;
        }

        try {
            this.updateReaction({ reaction, remove: false });
            await api.post(`${this.apiEndpoint}/reaction`, toUrl({ reaction }));
        } catch (e) {
            this.updateReaction({ reaction, remove: true });
            failRequest(e);
        }
    }

    async removeReaction(reaction) {
        try {
            this.updateReaction({ reaction, remove: true });
            await api.delete(`${this.apiEndpoint}/reaction`, { data: toUrl({ reaction }) });
        } catch (e) {
            this.updateReaction({ reaction, remove: false });
            failRequest(e);
        }
    }

    updateLabels({ label, remove = false }) {
        if (remove) {
            this.labels = this.labels.filter((el) => el.id !== label.id);
        } else if (!this.labels.some((el) => el.id === label.id)) {
            this.labels.push(label);
        }
    }

    async addLabel(label) {
        if (this.labelsIds.includes(label.id)) return;

        try {
            this.updateLabels({ label, remove: false });
            const { data } = await api.post(`${this.apiEndpoint}/labels/${label.id}`);
            this.updateModel(data);
        } catch (e) {
            this.updateLabels({ label, remove: true });
            failRequest(e);
        }
    }

    async removeLabel(label) {
        try {
            this.updateLabels({ label, remove: true });
            const { data } = await api.delete(`${this.apiEndpoint}/labels/${label.id}`);
            this.updateModel(data);
        } catch (e) {
            this.updateLabels({ label, remove: false });
            failRequest(e);
        }
    }
}
