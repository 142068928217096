import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function removeDomain(domain, index) {
    logEvent('Remove org domain', { domain });

    runInAction(() => {
        this.domains.splice(index, 1);
    });

    try {
        await api.delete('/organization/domain', {
            data: toUrl({ domain }),
        });
    } catch (error) {
        failRequest(error);
    }
}
