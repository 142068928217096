import React from 'react';

import { Position } from '@blueprintjs/core';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';

export default function BoardError({ message, error, position = Position.TOP }) {
    if (error || message) {
        const content = message || (
            <>
                <p>
                    This board is broken. The platform returns the error: <br />
                    <em>{error}</em>
                </p>
                <p>Please fix the error and update the board data</p>
            </>
        );

        return (
            <DarkTooltip content={content} position={position}>
                <CustomIcon color="orange" size={14} icon={CustomIconName.WARNING} />
            </DarkTooltip>
        );
    }
    return null;
}
