import { observer } from 'mobx-react-lite';

import { IS_VOTING_BOARD } from 'constants/global';

import screenStore from 'store/models/ScreenStore';

import DucalisLogo from 'components/DucalisLogo/DucalisLogo';
import MainVotingLogo from 'components/SideBar/VotingSideBarLogo/MainLogo';

const AuthFormLogo = ({ force }) => {
    if (!screenStore.isMobile && IS_VOTING_BOARD) {
        return <MainVotingLogo full />;
    } else if (!IS_VOTING_BOARD && force) {
        return <DucalisLogo height={42} width={197} />;
    }

    return null;
};

export default observer(AuthFormLogo);
