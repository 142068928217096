import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

export async function deleteLogo(attribute) {
    logEvent('Organization logo delete');

    try {
        await api.delete('/organization/upload-logo', {
            params: { attribute },
        });
    } catch (e) {
        failRequest(e);
        await Promise.reject(e);
    } finally {
        runInAction(() => {
            this.public_voting_settings[attribute] = null;
        });
    }
}
