import React from 'react';

import { InputGroup } from '@blueprintjs/core';

import styles from './CodeInput.module.sass';

export default function CodeInput({ onPaste, index, value, onChange, setRef, disabled }) {
    return (
        <InputGroup
            inputRef={setRef}
            data-place={`auth-code-${index}`}
            autoFocus={index === 0}
            onPaste={onPaste}
            className={styles.inputGroup}
            type="number"
            tabIndex={index + 1}
            large
            value={value}
            disabled={disabled}
            onChange={(e) => onChange(e, index)}
        />
    );
}
