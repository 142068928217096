import { runInAction } from 'mobx';

import api from 'api';

import logEvent from 'utils/logEvent';

export async function send11IssuesDone() {
    runInAction(() => {
        this.event_11_issues_sent = true;
    });
    await api.put('/user/event-11-issues-sent');
    logEvent('send11IssuesDone');
}
