import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';
import paymentPlans from 'store/models/PaymentPlans';

import { StHr } from 'atoms/StHr';
import { StPriceTableTotal } from 'atoms/StPriceTableTotal';

import formatPriceWithCurrency from 'utils/formatPriceWithCurrency';
import openChat from 'utils/openChat';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import Flex from 'components/Flex';

const EmailCalculator = () => {
    const location = window.location;
    const query = new URLSearchParams(location.search);

    if (mainStore.organization.paymentPlan?.is_custom) {
        return (
            <>
                <DarkTooltip
                    position="left"
                    popoverClassName="limit limit-300"
                    content="Your payment is based on how much you consume. You pay as you reach the limit of the package, not monthly or yearly."
                >
                    <Flex gap={4}>
                        <h3 style={{ margin: 0 }}>Pay as You Go Products</h3>
                        <CustomIcon size={14} icon={CustomIconName.HELP} className="o-4 o-4--hover" />
                    </Flex>
                </DarkTooltip>
                <div>Email credits</div>
                <Flex spaceBetween>
                    <div>Current Balance</div>
                    <b>{mainStore.organization.voting_email_settings?.emails_left?.toLocaleString('en-US')}</b>
                </Flex>
                <StHr />
                <p>
                    If you need to buy email credits, just{' '}
                    <u className="interactive" onClick={openChat}>
                        contact us
                    </u>
                </p>
            </>
        );
    }

    if (paymentPlans.currentEmailPlanId === 'free' && !paymentPlans.hasUpgradeEmailPackage && !query.get('emails')) {
        return null;
    }

    const totalPlan = paymentPlans.hasUpgradeEmailPackage
        ? paymentPlans.emailPackage
        : paymentPlans.currentEmailPackage;

    return (
        <>
            <DarkTooltip
                position="left"
                popoverClassName="limit limit-300"
                content="Your payment is based on how much you consume. You pay as you reach the limit of the package, not monthly or yearly."
            >
                <Flex gap={4}>
                    <h3 style={{ margin: 0 }}>Pay as You Go Products</h3>
                    <CustomIcon size={14} icon={CustomIconName.HELP} className="o-4 o-4--hover" />
                </Flex>
            </DarkTooltip>

            <DarkTooltip
                position="left"
                popoverClassName="limit limit-400"
                content={
                    <>
                        You have an allowance of email credits which allows you to send{' '}
                        <b>Release Notes and Voting boards email notifications</b> to your customers; and 1 email equals
                        1 <i>email credit</i>. You pay as you reach the limit of the credits.
                    </>
                }
            >
                <Flex gap={4}>
                    <div>Email credits</div>
                    <CustomIcon size={14} icon={CustomIconName.HELP} className="o-4 o-4--hover" />
                </Flex>
            </DarkTooltip>

            {paymentPlans.hasUpgradeEmailPackage &&
                paymentPlans.emailPackage?.package &&
                paymentPlans.emailPackage?.id !== 'free' && (
                    <Flex spaceBetween>
                        <div>Email Credits Top-up</div>
                        <b>+ {paymentPlans.emailPackage.package.toLocaleString('en-US')}</b>
                    </Flex>
                )}
            <Flex spaceBetween>
                <div>Current Balance</div>
                <b>{mainStore.organization.voting_email_settings?.emails_left?.toLocaleString('en-US')}</b>
            </Flex>

            <StHr css={{ margin: 0 }} />

            <div className="t-r">
                <StPriceTableTotal>
                    {formatPriceWithCurrency({
                        currencyCode: totalPlan?.currency,
                        price: totalPlan?.amount?.toLocaleString('en-US'),
                    })}
                </StPriceTableTotal>{' '}
                <span>/{totalPlan?.package?.toLocaleString('en-US')} credits</span>
                {paymentPlans.activePlan?.key !== 'free' &&
                    paymentPlans.currentEmailPlanId !== 'free' &&
                    paymentPlans.emailPackage?.id !== 'free' && (
                        <p className="t-r">
                            {paymentPlans.hasUpgradeEmailPackage ? 'Pay Now' : 'Pay after you run out of email credits'}
                        </p>
                    )}
            </div>
        </>
    );
};

export default observer(EmailCalculator);
