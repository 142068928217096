import api from 'api';
import { failRequest } from 'utils';

export async function getSecret() {
    try {
        const { data } = await api.get('/organization/reveal-app-secret');
        return data;
    } catch (e) {
        failRequest(e);
        return await Promise.reject();
    }
}
