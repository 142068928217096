import React, { createContext, useEffect, useState } from 'react';

import { Settings } from 'luxon';
import { observer } from 'mobx-react-lite';
import { IntlProvider } from 'react-intl';

import * as Language from 'lang';

import { mainStore } from 'store/models/MainStore';

export const Context = createContext(undefined);

function getMessages(locale) {
    return Language?.[locale] || Language.en;
}

function LangWrapper({ children }) {
    const language = mainStore.language;
    const [messages, setMessages] = useState(() => getMessages(language));

    useEffect(() => {
        language && (Settings.defaultLocale = language);
        language && setMessages(() => getMessages(language));
    }, [language]);

    if (!messages) return null;

    return (
        <Context.Provider value={{ locale: language }}>
            <IntlProvider messages={messages} locale={language}>
                {children}
            </IntlProvider>
        </Context.Provider>
    );
}

export default observer(LangWrapper);
