import api from 'api';

import { mainStore } from 'store/models/MainStore';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

const URL = '/organization/delete';

export async function removeAll(isConfirm) {
    if (!isConfirm) {
        logEvent('User get Remove Data');
        return api.get(URL);
    }

    try {
        logEvent('User Send Remove Account');
        await api.delete(URL, { data: toUrl({ approved: 1 }) });
        mainStore.db.dropDB();
        window.location.href = '/login/logout';
    } catch (e) {
        failRequest(e);
    }
}
