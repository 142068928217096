import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

export async function changeOrg(org) {
    logEvent('CHANGE_ORG', { to: org.name, from: this.name });

    try {
        await api.get('organization/change', { params: { id: org.id } });
        window.location.replace('/');
    } catch (e) {
        failRequest(e);
    }
}
