import React from 'react';

import { observer } from 'mobx-react-lite';

import { CriterionScore } from 'pages/Board/AlignmentList/components/CriterionScore';

function FinalScoreTableSuperScore({ criteria, votes }) {
    return criteria.map((criterion) => (
        <td key={criterion.id}>
            <CriterionScore criterion={criterion} votes={votes} isFinalScore canOpenDialog />
        </td>
    ));
}

export default observer(FinalScoreTableSuperScore);
