import Axios from 'axios';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function addDomain(domains) {
    logEvent('Add org domain', { domains });

    try {
        const requests = domains.map((domain) => api.post('/organization/domain', toUrl({ domain })));
        await Axios.all(requests);
    } catch (error) {
        failRequest(error);
    }
}
