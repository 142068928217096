import React from 'react';

import classNames from 'classnames';

import styles from './IssueBlockWrapper.module.sass';

export default function IssueBlockWrapper({ children, grow }) {
    const classes = classNames(styles.block, {
        'flex-grow': grow,
    });

    return <div className={classes}>{children}</div>;
}
