import React from 'react';

import { Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { utilsStore } from 'store/models/UtilsStore';

import { defaultPopoverProps, POPOVER_PROPS_DIV } from 'utils/consts';
import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import Flex from 'components/Flex';

const FinalScorePopoverMenu = ({ currentUserInBoard }) => {
    function toggleSection() {
        !utilsStore.settings.alignmentSlim && logEvent('Slim Issue Alignment');
        utilsStore.updateSettings({ field: 'alignmentSlim', value: !utilsStore.settings.alignmentSlim });
    }

    function toggleUsersLimit() {
        logEvent('Toggle Users Limit', { value: !utilsStore.settings.limitUsers });
        utilsStore.updateSettings({ field: 'limitUsers', value: !utilsStore.settings.limitUsers });
    }

    return (
        <Flex column align="none" css={{ padding: 4 }}>
            <Button
                block
                minimal
                onClick={toggleSection}
                className="bp5-popover-dismiss"
                text={utilsStore.settings.alignmentSlim ? 'Expand Users column' : 'Collapse Users column'}
                icon={utilsStore.settings.alignmentSlim ? CustomIconName.EXPAND_H : CustomIconName.COLLAPSE_LEFT}
            />
            <DarkTooltip
                {...POPOVER_PROPS_DIV}
                content={
                    utilsStore.settings.limitUsers ? undefined : 'Show team members only in teams I also assigned to'
                }
            >
                <Button
                    block
                    minimal
                    disabled={!currentUserInBoard}
                    onClick={toggleUsersLimit}
                    className="bp5-popover-dismiss"
                    text={utilsStore.settings.limitUsers ? 'Display all board Members' : 'Display less board members'}
                    icon={!utilsStore.settings.limitUsers ? CustomIconName.PERSONAL : CustomIconName.TEAM_ALT}
                />
            </DarkTooltip>
        </Flex>
    );
};

function SlimButton({ currentUserInBoard }) {
    return (
        <th width={utilsStore.settings.alignmentSlim ? 57 : undefined}>
            <Flex gap={4}>
                {!utilsStore.settings.alignmentSlim && <span className="flex-grow">Users</span>}
                <Popover
                    {...defaultPopoverProps}
                    content={<FinalScorePopoverMenu currentUserInBoard={currentUserInBoard} />}
                >
                    <Button minimal border size={20} icon={CustomIconName.SETTINGS} />
                </Popover>
            </Flex>
        </th>
    );
}

export default observer(SlimButton);
