import { CURRENT_USER_ID } from 'constants/global';

import { USER_STATUS } from './consts';

export function orderUsers(users) {
    return users.slice().sort((a, b) => {
        if (a.id === CURRENT_USER_ID) return -1;
        if (b.id === CURRENT_USER_ID) return 1;
        if (a.is_owner) return -1;
        if (b.is_owner) return 1;

        if (a.status === b.status) {
            return a.name > b.name ? 1 : -1;
        }

        if (a.status === USER_STATUS.removed) return 1;
        if (b.status === USER_STATUS.removed) return -1;

        if (a.status === USER_STATUS.pending) return 1;
        if (b.status === USER_STATUS.pending) return -1;

        return a.status - b.status;
    });
}
