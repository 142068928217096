import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';

export async function generateToken() {
    try {
        const { data } = await api.post('/users/tokens');
        runInAction(() => {
            this.tokens = data;
        });
        return true;
    } catch (e) {
        failRequest(e);
        return await Promise.reject();
    }
}
