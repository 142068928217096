import React from 'react';

import { PAYWALL_LIMITS, POPOVER_PROPS_DIV } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

export const CreateNewItemBlocked = ({ query }) => {
    const paywallProps = {
        text: 'to unlock this feature',
        helpArticle: 'public-idea-statuses',
        helpArticleText: 'Custom statuses',
    };
    return (
        <li>
            <UpgradePlanBtn paywall={PAYWALL_LIMITS.VOTING_CUSTOM_STATUSES} {...paywallProps} {...POPOVER_PROPS_DIV}>
                <Button
                    disabled
                    tabIndex="0"
                    icon={CustomIconName.PLUS}
                    text={`Create "${query}"`}
                    minimal
                    block
                    rightElm={<Button as="span" size={20} color="primary" minimal icon={CustomIconName.UPGRADE} />}
                />
            </UpgradePlanBtn>
        </li>
    );
};

export const CreateNewItemUseFull = ({ query, onClick, active }) => (
    <li>
        <Button
            minimal
            block
            tabIndex="0"
            active={active}
            icon={CustomIconName.PLUS}
            text={`Create "${query}"`}
            onClick={onClick}
        />
    </li>
);
