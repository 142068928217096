import React, { useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { VIEWS, VIEWS_DATA } from 'constants/Pages';

import useDisabledStatus from 'hooks/useDisabledStatus';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { PAYWALL_LIMITS } from 'utils/consts';

import { Button } from 'components/Button';
import Kbd, { KeyCombo } from 'components/Kbd';
import Tooltip from 'components/Tooltip';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

export const QButton = observer(function QButton({ item, rowIndex, text, hotkey }) {
    const useTableHotkey = (issuesList.activeIssue?.id === item.id && item.id !== -1) || hotkey;
    const isDisabledActivity = useDisabledStatus();

    const hotkeys = useMemo(() => {
        if (!useTableHotkey) return [];
        return [
            {
                combo: 'alt+A',
                global: true,
                group: 'Issue',
                label: 'Ask question',
                disabled: isDisabledActivity,
                preventDefault: true,
                stopPropagation: true,
                allowInInput: true,
                onKeyDown: () => {
                    issuesList.setQuestion({ issue: item });
                },
            },
        ];
    }, [item, useTableHotkey, isDisabledActivity]);

    useHotkeys(hotkeys);

    if (item.id === -1) {
        return null;
    }

    function togglePopover() {
        issuesList.setQuestion({ issue: item, row: rowIndex });
    }

    const tooltip = (
        <>
            <p>
                Ask a question about the issue if you can’t evaluate it. <br />
                The issue will be moved to Questions.
            </p>
            <KeyCombo>
                <Kbd>alt</Kbd>
                <Kbd>a</Kbd>
            </KeyCombo>
        </>
    );

    if (mainStore.organization.paymentRequired) {
        return (
            <UpgradePlanBtn paywall={PAYWALL_LIMITS.USERS_LIMIT} text="to ask a question">
                <Button
                    disabled
                    data-place="q-button"
                    text={text}
                    size={24}
                    minimal={!text}
                    icon={VIEWS_DATA[VIEWS.QUESTIONS].icon}
                    border
                />
            </UpgradePlanBtn>
        );
    }

    if (issuesList.questionIssue?.isRemoved) return null;

    return (
        <Tooltip content={tooltip} side="top">
            <Button
                data-place="q-button"
                text={text}
                onClick={togglePopover}
                size={24}
                minimal={!text}
                icon={VIEWS_DATA[VIEWS.QUESTIONS].icon}
                border
            />
        </Tooltip>
    );
});
