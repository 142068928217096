import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { StLogoCustomImage } from 'atoms/StLogoCustomImage';
import { StLogoString } from 'atoms/StLogoString';

function MainVotingLogo({ full }) {
    const name = mainStore.organization.publicName;

    if (mainStore.organization.logo) {
        return (
            <StLogoCustomImage
                full={full}
                src={mainStore.organization.logo}
                height={!full ? 28 : 'auto'}
                width={'auto'}
                alt={name}
            />
        );
    }

    return (
        <StLogoString title={name}>
            <span>{name}</span>
        </StLogoString>
    );
}

export default observer(MainVotingLogo);
