import React, { useEffect, useState } from 'react';

import { Collapse } from '@blueprintjs/core';

import useScrollTo from 'hooks/useScrollTo';

import {
    StCardStripe,
    StCardStripeBody,
    StCardStripeHeader,
    StCardStripeIcon,
    StCardStripeOpacityElement,
} from 'atoms/StCardStripe';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';

export default function Stripe({
    className,
    icon,
    title,
    subTitle,
    children,
    initialOpen = false,
    onClick,
    action,
    leftElm,
    openSubTitle,
    hideOpenAction,
    intent,
    subTitleShrink = true,
    stickyTop,
    scrollTo,
    iconColor,
    disabledContent,
    onOpenChange,
    activeIcon = false,
    viewBox,
    ...props
}) {
    const [open, setOpen] = useState(initialOpen);
    const ref = useScrollTo(open && scrollTo);

    useEffect(() => {
        initialOpen && setOpen(true);
    }, [initialOpen]);

    const handleClick = (e) => {
        if (e.target?.closest('.bp5-popover-content,[data-radix-menu-content]')) {
            return false;
        }
        onOpenChange && onOpenChange(!open);
        setOpen((state) => !state);
        onClick && onClick();
    };

    return (
        <StCardStripe
            className={className}
            open={open}
            data-state={open ? 'open' : 'closed'}
            intent={intent}
            ref={ref}
            {...props}
        >
            <StCardStripeHeader sticky={stickyTop} gap={8} as={Flex} role="button" onClick={handleClick} open={open}>
                {icon && (
                    <StCardStripeIcon
                        as={CustomIcon}
                        size={18}
                        icon={icon}
                        viewBox={viewBox}
                        active={!iconColor && activeIcon}
                        color={iconColor}
                    />
                )}
                {leftElm}

                {typeof title === 'string' ? (
                    <span title={title} className="t-crop">
                        {title}
                    </span>
                ) : (
                    title
                )}

                {(subTitle || (open && openSubTitle)) && (
                    <StCardStripeOpacityElement shrink={subTitleShrink} as={Flex} gap={8}>
                        {(open && openSubTitle) || subTitle}
                    </StCardStripeOpacityElement>
                )}
                <div className="flex-grow" />
                {hideOpenAction && open ? null : action}
                <Button
                    minimal
                    border
                    size={24}
                    rightElm={
                        <CustomIcon
                            icon={open ? CustomIconName.CHEVRON_DOWN : CustomIconName.CHEVRON_RIGHT}
                            size={14}
                        />
                    }
                />
            </StCardStripeHeader>

            <Collapse isOpen={open}>
                <StCardStripeBody aria-disabled={disabledContent}>{children}</StCardStripeBody>
            </Collapse>
        </StCardStripe>
    );
}
