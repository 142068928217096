import { observer } from 'mobx-react-lite';

import { CRITERION_SCALE_TYPE } from 'constants/Criterion';

import criteriaStore from 'store/models/CriteriaStore';

import CellWrapper from 'components/CellWrapper';
import HiddenContent from 'components/MasterTable/components/HiddenContent/HiddenContent';
import Tooltip from 'components/Tooltip';

import { AssessmentGridLink } from './AssessmentGridLink';

export const ScoreCell = observer(({ issue }) => {
    if (issue?.board?.hideScores) {
        return (
            <CellWrapper>
                <HiddenContent revealAt={issue.board.revealDateString} />
            </CellWrapper>
        );
    }
    if (issue.totalValue === undefined) {
        return <AssessmentGridLink />;
    }

    return (
        <Tooltip side="right" content={<ScoreCellTooltipContent issue={issue} />}>
            <CellWrapper isNumber>{issue.totalValue}</CellWrapper>
        </Tooltip>
    );
});

const ScoreCellTooltipContent = observer(({ issue }) => {
    const evaluationStatus = issue.all_voted
        ? 'Task is evaluated by all teammates'
        : 'Task is not evaluated yet by all teammates';
    const criteriaIds = issue.boardCriteriaScores;

    let formulaDes = null;
    if (
        !issue.totalValue &&
        issue.board.settings.formula === 'wsjf' &&
        issue.voting_percent &&
        issue.voting_percent < 100
    ) {
        formulaDes = (
            <>
                <p>
                    The task lacks an ‘effort’ score, but your formula divides by it.
                    <br />
                    Update the formula or rate by ‘effort’.
                </p>
            </>
        );
    }

    return (
        <>
            <p>{evaluationStatus}</p>
            {formulaDes}
            {criteriaIds?.length ? (
                <div className="table-wrpp">
                    <table className="cr-table">
                        <tbody>
                            {criteriaIds.map((criterionId) => (
                                <CriterionScoreRow criterionId={criterionId} issue={issue} key={criterionId} />
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : null}
        </>
    );
});

const CriterionScoreRow = observer(({ issue, criterionId }) => {
    const finalVote = issue.externalData.finalVotes.get(criterionId);
    const value = issue.externalData.cr_weightless.get(criterionId);

    if (!issue.board) return null;

    const criterion = criteriaStore.criteriaIds.get(criterionId);
    const boardCriterion = criteriaStore.criteriaBoardsIds.get(issue.board.getBoardCriterionId(criterionId));

    if (!criterion) return null;

    const finalValue = finalVote?.value ?? value;
    const boardCriterionCoefficient = boardCriterion?.coefficient ?? 1;
    const postfix = criterion?.scale_type === CRITERION_SCALE_TYPE.percent ? '%' : '';

    return (
        <tr>
            <td>{criterion.name}</td>
            <td className="t-r table-numbers t-nw">
                {`${finalValue} x ${boardCriterionCoefficient} = ${Math.round(boardCriterionCoefficient * finalValue * 100) / 100}${postfix}`}
            </td>
        </tr>
    );
});
