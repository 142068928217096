import React from 'react';

import { observer } from 'mobx-react-lite';

import Avatar from 'components/Avatar';
import DarkTooltip from 'components/DarkTooltip';

import { TooltipContent } from './TooltipContent';

function AvatarTooltip({ user, content, tooltipProps, size = 50, hideStatus = false, onClick }) {
    const { name, email, status, invitedDate } = user;
    const tooltip = <TooltipContent name={name || email} invited={status === -1 && invitedDate} content={content} />;

    return (
        <DarkTooltip position="top" {...tooltipProps} content={tooltip}>
            <Avatar
                onClick={onClick ? () => onClick(user) : undefined}
                user={user}
                size={size}
                hideStatus={hideStatus}
            />
        </DarkTooltip>
    );
}

export default observer(AvatarTooltip);
