import React from 'react';

import { AnchorButton } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { getAuthButtonUrl } from 'utils/getAuthButtonUrl';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';

const SsoButton = ({ isLogin, isUpdater, serviceName, authClient, icon = CustomIconName.KEY }) => {
    return (
        <AnchorButton
            fill
            icon={<CustomIcon icon={icon} size={24} />}
            large
            className="bp5-button--sso"
            href={getAuthButtonUrl({ isLogin, authClient })}
            text={
                isUpdater ? (
                    <FormattedMessage
                        id="auth.change.sso_btn"
                        defaultMessage="Change email via {serviceName}"
                        values={{ serviceName }}
                    />
                ) : !isLogin ? (
                    <FormattedMessage
                        id="auth.signup.sso_btn"
                        defaultMessage="Sign Up with {serviceName}"
                        values={{ serviceName }}
                    />
                ) : (
                    <FormattedMessage
                        id="auth.sso_btn"
                        defaultMessage="Log In with {serviceName}"
                        values={{ serviceName }}
                    />
                )
            }
        />
    );
};

export default observer(SsoButton);
