import restApi from 'api';
import { failRequest } from 'utils';

export async function getSyncFields() {
    if (!this.sync) return;

    try {
        const { data } = await restApi.get(`${this.apiEndpoint}/main-sync-fields`);
        this.fillSyncFields({ selectedSyncFields: data.selected_fields, availableSyncFields: data.available_fields });
    } catch (e) {
        failRequest(e);
    }
}

export async function getAvailableSyncFields(query) {
    if (!this.sync) return;

    try {
        const { data } = await restApi.get(`${this.apiEndpoint}/available-sync-fields`, { params: { q: query } });
        return data;
    } catch (e) {
        failRequest(e);
    }
}
