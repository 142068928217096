import { AnchorButton } from 'components/Button';

export default function HelpArticleLink({ article, children, className, ...props }) {
    return (
        <AnchorButton
            size={20}
            likeLink
            active
            className={className}
            target="_blank"
            href={/^http/.test(article) ? article : `https://help.ducalis.io/knowledge-base/${article}`}
            {...props}
        >
            {children}
        </AnchorButton>
    );
}
