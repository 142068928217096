import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';

import { DICTIONARY_LABELS, DICTIONARY_LABELS_VOTING } from 'utils/consts';

import IssueDictionaryMultiSelect from 'components/DictionaryValueSelect/DictionaryMultiSelect/IssueDictionaryMultiSelect';

function CustomLabel({ issue, isVoting }) {
    return (
        <IssueDictionaryMultiSelect
            dictionary={isVoting ? DICTIONARY_LABELS_VOTING : DICTIONARY_LABELS}
            issue={issue || issuesList.activeIssue}
        />
    );
}

export default observer(CustomLabel);
