import React from 'react';

import DarkTooltip from 'components/DarkTooltip';

export default function EmptyUserTotalValue({ issue }) {
    const board = issue.board;

    if (!board) {
        return null;
    }

    return (
        <DarkTooltip fast className="flex-grow" position="right" content="No public idea for this issue">
            <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
        </DarkTooltip>
    );
}
