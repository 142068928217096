import React, { useCallback, useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import useDisabledStatus from 'hooks/useDisabledStatus';
import useUndoNotification from 'hooks/useUndoNotification';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { PAYWALL_LIMITS } from 'utils/consts';
import logEvent from 'utils/logEvent';

import { AnchorButton, Button } from 'components/Button';
import CellWrapper from 'components/CellWrapper';
import { CustomIconName } from 'components/CustomIcon';
import { DisabledScoreValueTooltip } from 'components/DisabledScoreValueTooltip/DisabledScoreValueTooltip';
import DotDivider from 'components/DotDivider';
import Kbd, { KeyCombo } from 'components/Kbd';
import { QButton } from 'components/QButton/QButton';
import Tooltip from 'components/Tooltip';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

function SkipColumn({ issue, rowIndex }) {
    const notify = useUndoNotification();

    const isDisabledActivity = useDisabledStatus();

    const skipEvaluation = useCallback(async () => {
        await issue.skipEvaluation();

        notify(
            <>
                Skipped issue evaluation.
                <br />
                The issue has been moved to{' '}
                <AnchorButton inline likeLink minimal active href={`${issue.board.basePath}/unvoted/scores`}>
                    Scores Filter
                </AnchorButton>
            </>,
            () => {
                logEvent('Skip evaluation - Undo');
                issue.resumeEvaluation({ user_id: mainStore.currentUser.id });
            },
        );
    }, [issue, notify]);

    const isActive = issuesList.activeIssue?.id === issue.id && issue.id !== -1;

    const hotkeys = useMemo(() => {
        if (!isActive || isDisabledActivity) {
            return [];
        }
        return [
            {
                combo: 'ctrl+shift+right',
                global: true,
                label: 'Skip evaluation of this issue',
                group: 'Issue',
                preventDefault: true,
                stopPropagation: true,
                allowInInput: true,
                onKeyDown: skipEvaluation,
            },
        ];
    }, [isActive, skipEvaluation, isDisabledActivity]);

    useHotkeys(hotkeys);

    if (!isActive) return null;

    if (
        mainStore.organization.paymentRequired ||
        !mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.SKIP_ISSUE_VOTE)
    ) {
        return (
            <CellWrapper>
                <UpgradePlanBtn
                    paywall={PAYWALL_LIMITS.SKIP_ISSUE_VOTE}
                    text="to unlock this feature"
                    helpArticleText="Skip Evaluation"
                    helpArticle="skipping-issues-during-evaluation"
                >
                    <Button disabled size={24} minimal icon={CustomIconName.REWIND} border />
                </UpgradePlanBtn>
                <div className="btn-row-hovered">
                    <QButton rowIndex={rowIndex} item={issue} />
                </div>
            </CellWrapper>
        );
    }

    if (issue?.board?.disableEvaluation) {
        return (
            <CellWrapper>
                <DisabledScoreValueTooltip date={issue.board.sprint?.ends_at} isAdmin={mainStore.currentUser?.isAdmin}>
                    <Button disabled size={24} minimal icon={CustomIconName.REWIND} border />
                </DisabledScoreValueTooltip>
                <div className="btn-row-hovered">
                    <QButton rowIndex={rowIndex} item={issue} />
                </div>
            </CellWrapper>
        );
    }

    return (
        <CellWrapper>
            <Tooltip
                side="top"
                align="end"
                content={
                    <>
                        Skip evaluation of this issue
                        <DotDivider />
                        <KeyCombo>
                            <Kbd>ctrl</Kbd> <Kbd>shift</Kbd> <Kbd>→</Kbd>
                        </KeyCombo>
                    </>
                }
            >
                <div className="btn-row-hovered">
                    <Button
                        disabled={isDisabledActivity}
                        size={24}
                        minimal
                        icon={CustomIconName.REWIND}
                        border
                        onClick={skipEvaluation}
                    />
                </div>
            </Tooltip>
            <div className="btn-row-hovered">
                <QButton rowIndex={rowIndex} item={issue} />
            </div>
        </CellWrapper>
    );
}

export default observer(SkipColumn);
