import { Popover } from '@blueprintjs/core';
import classNames from 'classnames';

export default function PopoverTooltip({
    position = 'left',
    content,
    interactionKind = 'hover',
    captureDismiss = false,
    ...props
}) {
    const popoverClassName = classNames('bp5-tooltip', props.popoverClassName);

    const popoverContent =
        typeof content === 'string' ? (
            <div className="t-size-13" dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
            <div className="t-size-13">{content}</div>
        );

    return (
        <Popover
            {...props}
            position={position}
            captureDismiss={captureDismiss}
            content={popoverContent}
            interactionKind={interactionKind}
            minimal
            popoverClassName={popoverClassName}
        />
    );
}
