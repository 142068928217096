import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

export async function changeLogo(logo, attribute) {
    const formData = new FormData();
    formData.append('logo', logo);

    logEvent('Organization logo change', formData);

    try {
        const { data } = await api.post('/organization/upload-logo', formData, {
            params: { attribute },
        });
        runInAction(() => {
            this.public_voting_settings[attribute] = data.public_voting_settings[attribute];
        });
        return true;
    } catch (e) {
        failRequest(e);
        return await Promise.reject(e);
    }
}
