import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function setColor(colors) {
    runInAction(() => {
        Object.entries(colors).forEach(([key, value]) => (this.public_voting_settings[key] = value));
    });

    logEvent('Organization color change');

    try {
        await api.put('/organization/voting-settings', toUrl(colors));
    } catch (e) {
        failRequest(e);
        await Promise.reject(e);
    }
}
