import { FormattedMessage } from 'react-intl';

import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

import { Button } from 'components/Button';

function InviteError({ email, type, invitedEmail, setInviteError }) {
    async function handleClick() {
        if (type === 'email') {
            try {
                await api.post(`/auth/send-magic?&scenario=default`, toUrl({ email, clearInvite: 1 }));
                window.location.href = '/';
            } catch (e) {
                failRequest(e);
            }
        } else {
            try {
                await api.get(`/auth/clear-invite`);
                window.location.href = '/login/auth?authclient=google&scenario=default';
            } catch (e) {
                failRequest(e);
            }
        }
    }

    return (
        <div className="flex flex-column flex-center flex-a-center">
            <p className="t-c" style={{ fontSize: 24 }}>
                <FormattedMessage
                    id="auth.invite-error"
                    values={{
                        br: () => <br />,
                        invitedEmail,
                        email,
                        b: (children) => <b>{children}</b>,
                    }}
                />
            </p>
            <div className="h-20" />
            <div className="flex flex-a-center">
                <Button data-place="invite-relogin" color="primary" onClick={() => setInviteError({})}>
                    <FormattedMessage
                        id="auth.invite-error.relogin"
                        defaultMessage={`Relogin as <b>{invitedEmail}</b>`}
                        values={{
                            invitedEmail,
                            b: (children) => <b>{children}</b>,
                        }}
                    />
                </Button>
                <div className="w-5"></div>
                <FormattedMessage id="global.or" defaultMessage="or" />
                <div className="w-5"></div>
                <Button data-place="skip-invite" color="green" onClick={handleClick}>
                    <FormattedMessage
                        id="auth.invite-error.continue"
                        defaultMessage={`Continue as <b>{email}</b>`}
                        values={{
                            email,
                            b: (children) => <b>{children}</b>,
                        }}
                    />
                </Button>
            </div>
        </div>
    );
}

export default InviteError;
