import React from 'react';

import { PAYWALL_LIMITS, POPOVER_PROPS_DIV } from 'utils/consts';

import { Button } from 'components/Button';
import DarkTooltip from 'components/DarkTooltip';
import RemoveTooltip from 'components/DictionaryValueSelect/DictionaryColorsMenu/RemoveStatusTooltips';
import { WithPaywallRule } from 'components/WithPaywallRule/WithPaywallRule';

const ComponentBlocked = ({ icon, text }) => <Button minimal size={28} block icon={icon} text={text} disabled />;
export const ComponentUseFull = ({ isSystem, systemStatus, name, isIdeaStatus, count, ...props }) => {
    const removeBtn = <Button minimal size={28} block {...props} />;

    if (isSystem) {
        return (
            <DarkTooltip
                {...POPOVER_PROPS_DIV}
                position="right"
                popoverClassName="limit limit-300"
                content={<RemoveTooltip isDone={systemStatus === 'done'} name={name} isIdeaStatus={isIdeaStatus} />}
            >
                {removeBtn}
            </DarkTooltip>
        );
    } else if (count === 1) {
        return (
            <DarkTooltip {...POPOVER_PROPS_DIV} content="You can't remove the last item">
                {removeBtn}
            </DarkTooltip>
        );
    }

    return removeBtn;
};

export const RemoveItemBtn = WithPaywallRule(
    PAYWALL_LIMITS.VOTING_CUSTOM_STATUSES,
    {
        text: 'to unlock this feature',
        helpArticle: 'public-idea-statuses/',
        helpArticleText: 'Custom statuses',
    },
    { ComponentUseFull, ComponentBlocked }
);
