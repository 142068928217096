import { storageApi } from 'api';
import { failRequest } from 'utils';

import criteriaStore from 'store/models/CriteriaStore';
import dictionaryStore from 'store/models/DictionaryStore';
import { issuesList } from 'store/models/IssuesList';

import { prepareServerIssuesResponse } from 'utils/prepareServerIssuesResponse';

export async function fetchPublic() {
    try {
        const { data } = await storageApi.get('/public', { params: { id: this.publicBoard.public_id } });

        dictionaryStore.fillCollection(data.dictionaries);
        this.platformsList.set(data.platforms);
        this.users.setCurrentUser(data.me);

        const issuesScore = prepareServerIssuesResponse(data.issues_scores);
        issuesList.fillIssuesData(issuesScore);

        const preparedIssuesList = prepareServerIssuesResponse(data.issues);
        issuesList.fillData(preparedIssuesList);

        this.organization.updateModel(data.organization);
        this.boardsList.set(data.boards);
        this.fillLinkedIssues(data.issues_links);

        criteriaStore.fillData(data.criteria, data['boards-criteria']);

        this.setReady();
    } catch (error) {
        failRequest(error);
    }
}
