import { runInAction } from 'mobx';

import api from 'api';
import { VIEWS } from 'constants/Pages';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import delay from 'utils/delay';
import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

export async function skipEvaluation() {
    logEvent('Skip evaluation');

    if (mainStore.page === VIEWS.EVALUATION) {
        this.board.getNextIssue(issuesList.row);

        runInAction(() => {
            this.isRemoved = true;
        });

        delay(300).then(() => {
            runInAction(() => {
                this.externalData.unvoted = this.externalData.isUserNotAllVoted = false;
                this.externalData.skipped = true;
            });
        });
    }

    try {
        await api.post(`${this.apiCurrentEndpoint}/vote-skip`);
    } catch (e) {
        failRequest(e);
        runInAction(() => {
            this.isRemoved = false;
            this.externalData.unvoted = this.externalData.isUserNotAllVoted = true;
            this.externalData.skipped = false;
        });
    } finally {
        runInAction(() => {
            this.isRemoved = false;
        });
    }
}
