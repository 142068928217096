import React from 'react';

export function TooltipContent({ name, invited, content }) {
    return (
        <>
            <div>{name}</div>

            {invited && <div>Invited: {invited}</div>}

            {content && <div>{content}</div>}
        </>
    );
}
