import React, { useContext, useRef } from 'react';

import dictionaryStore from 'store/models/DictionaryStore';

import { ColorsVariants } from 'atoms/Colors';
import { StHr } from 'atoms/StHr';

import { DICTIONARY_LABELS_VOTING, DICTIONARY_STATUSES_VOTING } from 'utils/consts';
import debounce from 'utils/debounce';
import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Dot from 'components/Dot';
import Flex from 'components/Flex';

import { EditItemName } from './EditItemName';
import * as RemoveItemBtn from './RemoveItemBtn';

import DictionaryContext from '../DictionaryContext';

export const DictionaryColorsMenuContent = ({ item, onRemove, children, onClose }) => {
    const { dictionary, count, board } = useContext(DictionaryContext);
    const inputRef = useRef();

    function changeColor(color) {
        logEvent(`Update ${dictionary} item`, { type: 'color' });
        const updateParams = { item, dictionary, id: item.id, data: { color } };
        if ([DICTIONARY_STATUSES_VOTING, DICTIONARY_LABELS_VOTING].includes(dictionary)) {
            board.updateDictItem(updateParams);
        } else {
            dictionaryStore.updateDictItem(updateParams);
        }

        onClose && onClose();
    }

    const saveName = debounce((name) => {
        if (!name) {
            return null;
        }
        if (item.name !== name) {
            logEvent(`Update ${dictionary} item`, { type: 'name' });
            const requestParams = { dictionary, id: item.id, data: { name } };
            if ([DICTIONARY_STATUSES_VOTING, DICTIONARY_LABELS_VOTING].includes(dictionary)) {
                board.updateDictItem(requestParams);
            } else {
                dictionaryStore.updateDictItem(requestParams);
            }
            onClose && onClose();
        }
        inputRef.current?.handleFocus();
    }, 300);

    function removeItem() {
        onRemove(item);
        onClose && onClose();
    }

    const isIdeaStatus = dictionary === DICTIONARY_STATUSES_VOTING;
    const disabledRemove = item.is_system || count === 1;

    const remove = isIdeaStatus ? (
        <RemoveItemBtn.RemoveItemBtn
            isSystem={item.is_system}
            systemStatus={item.system_status}
            name={item.name}
            isIdeaStatus={isIdeaStatus}
            count={count}
            disabled={disabledRemove}
            onClick={removeItem}
            icon={CustomIconName.TRASH}
            text="Remove"
        />
    ) : (
        <RemoveItemBtn.ComponentUseFull
            isSystem={item.is_system}
            systemStatus={item.system_status}
            name={item.name}
            isIdeaStatus={isIdeaStatus}
            count={count}
            disabled={disabledRemove}
            onClick={removeItem}
            icon={CustomIconName.TRASH}
            text="Remove"
        />
    );

    const itemName = (
        <div style={{ padding: '4px 4px', minWidth: 150, maxWidth: 200 }}>
            <EditItemName
                isEditing
                inputRef={inputRef}
                onConfirm={saveName}
                fill
                selectAllOnFocus
                defaultValue={item.name}
                confirmOnEnterKey
            />
        </div>
    );

    if (isIdeaStatus) {
        return (
            <Flex column align="none" css={{ maxHeight: 400, padding: 6 }}>
                {itemName}
                {children}
                {/* INFO: don`t show actions https://concertwithme.atlassian.net/browse/DCLS-8633 */}
                {/*<IdeaStatusSystemButtons onClose={onClose} status={item} />*/}
                {remove}
            </Flex>
        );
    }

    return (
        <Flex column align="none" css={{ maxHeight: 400, padding: 4, overflow: 'auto' }}>
            {itemName}
            {remove}
            <StHr />
            {ColorsVariants.map((color) => (
                <Button
                    minimal
                    size={28}
                    key={color}
                    onClick={(e) => changeColor(color, e)}
                    leftElm={<Dot color={color} style={{ marginRight: 4 }} />}
                    text={color}
                    rightIcon={color === item.color ? CustomIconName.TICK : undefined}
                />
            ))}
        </Flex>
    );
};
