import { makeAutoObservable, runInAction } from 'mobx';

import { VIEWS } from 'constants/Pages';
import { PROVIDER_DUCALIS } from 'constants/Providers';
import { toUrl } from 'utils';

import * as IssuesFilterApi from 'store/models/api/IssuesFilter';
import { FilterField } from 'store/models/FilterField';
import { Idea } from 'store/models/Idea';
import { mainStore } from 'store/models/MainStore';

import { FILTER_TYPES } from 'utils/consts';
import { sendToSentry } from 'utils/sentry';

export class IssuesFilter {
    id = null;
    name = 'New Filter Name';
    fieldsMap = new Map();
    user_id = null;
    favorites = [];
    form = null;
    counter = null;
    grid = '';
    operator = 'and';
    board_id = null;
    report_id = null;
    is_pinned = false;
    is_global = false;
    /** Number[] **/
    ids = null;
    position = null;

    loader = null;
    hasChanges = false;

    // eslint-disable-next-line no-unused-vars
    constructor({ fields, grid, operator, organization_id, ...props }) {
        this.grid = grid === VIEWS.SCORES ? VIEWS.EVALUATION : grid;
        this.operator = operator || this.operator;

        Object.assign(this, props);

        fields.forEach((field) => {
            this.fieldsMap.set(field.name, new FilterField(field));
        });

        makeAutoObservable(this);
    }

    addField(field) {
        if (!this.fieldsMap.has(field.name)) {
            this.fieldsMap.set(field.name, new FilterField(field));
            this.hasChanges = true;
        }
    }

    removeField(field) {
        this.fieldsMap.delete(field.name);
        this.hasChanges = true;
    }

    updateField(fieldName, obj) {
        const field = this.fieldsMap.get(fieldName);
        field.fillProps(obj);
        this.hasChanges = true;
    }

    setOperator(operator = 'and') {
        this.operator = operator;
        this.hasChanges = true;
    }

    setName(name) {
        this.name = name;
        this.hasChanges = true;
    }

    setId(id) {
        this.id = id;
    }

    clearForm() {
        this.form = null;
    }

    get hash() {
        return JSON.stringify(this.fields.filter((field) => ({ [field.name]: field.value })));
    }

    get author() {
        return mainStore.users.usersIds.get(this.user_id);
    }

    get filter() {
        return this.fields.filter((field) => field.isActive);
    }

    /**
     * @return {FilterField[]}
     */
    get fields() {
        return Array.from(this.fieldsMap.values());
    }

    get asJson() {
        return {
            id: this.id,
            name: this.name,
            fields: this.filter,
            grid: this.grid,
            operator: this.operator,
            board_id: this.board_id,
            report_id: this.report_id,
            is_pinned: this.is_pinned,
            is_global: this.is_global,
        };
    }

    get companyFilter() {
        const requestData = {
            fields: this.filter.filter((el) => el.fieldType === 'company').map((field) => field.getJS()),
            operator: this.operator,
        };
        if (requestData.fields.length === 0) return null;
        return toUrl(requestData);
    }

    get hasCompanyFields() {
        return this.fields.some((el) => el?.fieldType === 'company');
    }

    getClearJson() {
        const obj = this.asJson;
        return { ...obj, fields: obj.fields.map((el) => el.getJS()) };
    }

    everyFilterRules = (issue) => {
        return this.filter.every((field) => this.filterField(field, issue));
    };

    someFilterRules = (issue) => {
        return this.filter.some((field) => this.filterField(field, issue));
    };

    prepareValueForFilter(value, field) {
        if (!value) return value;
        if (FILTER_TYPES.USER === field.dataType) {
            return typeof value === 'string'
                ? value
                : [value?.name, String(value?.email).toLowerCase()].filter((el) => el !== 'undefined');
        }
        if (FILTER_TYPES.USER_LIST === field.dataType) {
            const arrayValue = Array.isArray(value) ? value : [value];
            return arrayValue
                ?.map((item) => (typeof item === 'string' ? item : [item?.name, String(item?.email).toLowerCase()]))
                .flat()
                .filter((el) => el !== 'undefined');
        }
        return value;
    }

    filterField(field, issue) {
        let issueFieldValue = undefined;
        const isIdea = issue instanceof Idea;

        const issueModel = isIdea ? issue.parentIssue : issue;

        if (field.fieldType === 'company') {
            if (this.loader) return true;
            if (this.ids) {
                return isIdea ? this.ids.includes(issue.id) : issue.ideasIds.some((id) => this.ids.includes(id));
            }
            return undefined;
        }

        if (field.votingField && field.votingField !== '0') {
            issueFieldValue = isIdea
                ? issue?.[field.votingField]
                : issue.ideas.length === 0
                  ? undefined
                  : issue.ideas.map((idea) => idea?.[field.votingField]);
        } else if (issueModel) {
            const filterField =
                field.user_id ||
                field.criterion_id ||
                (issue.provider === PROVIDER_DUCALIS && field.ducalis_field) ||
                field.name;
            issueFieldValue = issueModel.getValueByField(filterField, undefined, field.fieldType);
            issueFieldValue = this.prepareValueForFilter(issueFieldValue, field);
        }

        try {
            if (issueFieldValue && field.votingField && !isIdea && Array.isArray(issueFieldValue)) {
                return issueFieldValue.some((value) => field.filterValues(value));
            }
            return field.filterValues(issueFieldValue);
        } catch (error) {
            sendToSentry('Fail filterValues', { error, field, issueFieldValue });
            console.error('Fail filterValues', error, { field, issueFieldValue });
            return false;
        }
    }

    useFilter(issues) {
        const filterFn = this.operator === 'and' ? this.everyFilterRules : this.someFilterRules;
        const list = issues.filter(filterFn);
        runInAction(() => {
            this.counter = list.length;
        });
        return list;
    }

    // API methods list
    getForm = IssuesFilterApi.getForm;

    getIds = IssuesFilterApi.getIds;
}
