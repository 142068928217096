import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function changePublicName(publicName) {
    logEvent('Organization public name change');

    const putData = toUrl({ publicName });

    try {
        await api.put('/organization/voting-settings', putData);
    } catch (e) {
        failRequest(e);
    }
}
