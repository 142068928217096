import api from 'api';

import { issuesList } from 'store/models/IssuesList';

import delay from 'utils/delay';
import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function sendQuestion({ message, assignee_id }) {
    logEvent('Send question', { assignee_id });
    this.setRemoved(true);
    this.board.getNextIssue(issuesList.row);

    try {
        await api.post(`/requests`, toUrl({ issue_id: this.id, message, board_id: this.board.id, assignee_id }));
    } catch (error) {
        failRequest(error);
    } finally {
        delay(1000).then(() => this.setRemoved(false));
    }
}
