import React from 'react';

import { Button } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { VIEWS } from 'constants/Pages';

import alertStore from 'store/models/Alert';
import criteriaStore from 'store/models/CriteriaStore';
import { Idea } from 'store/models/Idea';
import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { StLineThrough } from 'atoms/StLineThrough';

import getDateTimeForTimezone from 'utils/getDateTimeForTimezone';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Space from 'components/Space';
import Tooltip from 'components/Tooltip';

import { StLightBoldText } from './StLightBoldText';
import { StLightText } from './StLightText';

const RewriteValueTooltipWithActions = observer(({ value, vote, criterion }) => {
    function handleClick() {
        alertStore.confirm({
            message: 'Are you sure you want to undo edit the Final score?',
            onConfirm: rollBack,
            confirmButtonText: 'Undo',
        });
    }

    async function rollBack() {
        if (issuesList.activeIssue instanceof Idea) {
            await issuesList.activeIssue.parentIssue.vote({ criterion: criterion, vote: null, custom: true });
        } else {
            await issuesList.activeIssue.vote({ criterion: criterion, vote: null, custom: true });
        }
    }

    if (
        vote.approved &&
        (![VIEWS.ALIGNMENT, VIEWS.USERS_ALIGNMENT, VIEWS.CRITERIA_ALIGNMENT, VIEWS.TOP, VIEWS.REPORT].includes(
            mainStore.page,
        ) ||
            criterion.id === criteriaStore.alignCriterionId)
    ) {
        return <RewriteValueTooltip value={value} criterion={criterion} vote={vote} />;
    }

    return (
        <div>
            <Space height={8} />
            <RewriteValueTooltip value={value} criterion={criterion} vote={vote} />
            <Space height={8} />
            {vote.approved ? (
                <Button
                    small
                    onClick={() => criteriaStore.setAlignCriterionId(criterion.id)}
                    icon={<CustomIcon icon={CustomIconName.ALIGNMENT} className="o-6" />}
                    text="Open Dialog"
                />
            ) : (
                <Button
                    small
                    onClick={handleClick}
                    icon={<CustomIcon icon={CustomIconName.RESET} className="o-6" />}
                    text="Undo Manual Edit"
                />
            )}
        </div>
    );
});

const RewriteValueTooltip = observer(({ value, vote, criterion }) => {
    const user = vote && mainStore.users.usersIds.get(vote.user_id);
    const updatedDate = getDateTimeForTimezone(vote.updated, mainStore.organization.timezone);

    return (
        <>
            <Flex tagName={StLightBoldText} gap={4}>
                <span>{value}</span>
                <span>-></span>
                <span>{vote.value}</span>
            </Flex>
            <div>
                <StLightText>{user?.name}</StLightText> {vote.approved ? 'approved' : 'edited'}{' '}
                <StLightText>{criterion.name}</StLightText> final score on{' '}
                <StLightText>{updatedDate.toFormat('DD')}</StLightText>
            </div>
        </>
    );
});

export const RewriteValue = observer(({ disabled, criterion, value, vote, hideTooltip }) => {
    const valueElm = (
        <Flex gap={6} align="end">
            <CustomIcon size={16} className="o-4" icon={CustomIconName.RESET} />
            <StLineThrough>{value}</StLineThrough>
        </Flex>
    );

    if (
        hideTooltip ||
        !issuesList.activeIssue.board.userCanChangeCriteriaFinalScore(mainStore.currentUser.id, criterion.id)
    ) {
        return (
            <Tooltip
                contentClassName="limit limit-280"
                side="right"
                content={<RewriteValueTooltip value={value} criterion={criterion} vote={vote} />}
            >
                {valueElm}
            </Tooltip>
        );
    }

    if (disabled) return valueElm;

    return (
        <Tooltip
            disableHoverableContent={false}
            contentClassName="limit limit-280"
            side="right"
            content={<RewriteValueTooltipWithActions vote={vote} value={value} criterion={criterion} />}
        >
            {valueElm}
        </Tooltip>
    );
});
