import { runInAction } from 'mobx';

import restApi from 'api';
import { failRequest } from 'utils';

export async function runDnsCheck() {
    const oldStatus = String(this.voting_email_settings.dns_records_status);
    try {
        runInAction(() => {
            this.voting_email_settings.dns_records_status = 'in_progress';
        });
        await restApi.post('/organization/voting-email-check');
    } catch (e) {
        failRequest(e);
        runInAction(() => {
            this.voting_email_settings.dns_records_status = oldStatus;
        });
    }
}
