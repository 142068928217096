import { sendToSentry } from 'utils';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import appStorage, { APP_STORAGE_KEYS } from 'utils/AppStorage';

export function fakeBtnLinkHandler(e) {
    try {
        const model = issuesList.activeIssue?.board || mainStore.activeBoard;

        if (!model) return;

        switch (e.target.className) {
            case 'js-action--sync-now':
                e.preventDefault();
                model.platformSync.runSync({ withSync: true });
                break;

            case 'js-action--auth':
                e.preventDefault();
                appStorage.set(APP_STORAGE_KEYS.board_sync, model.id);
                const WRITE_AUTH_URL = model.platform._links.auth_url;
                window.location.href = `${WRITE_AUTH_URL}&redirect_url=${window.location.href}`;
                break;

            case 'js-action--update-board':
                e.preventDefault();
                model.platformSync.runSync({ withSync: false });
                break;

            default:
                break;
        }
    } catch (error) {
        sendToSentry('Fail fakeBtnLinkHandler', error);
    }
}
