import { runInAction, toJS } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function revokeToken(id) {
    const tokens = toJS(this.tokens);
    runInAction(() => {
        this.tokens = this.tokens.filter((token) => token.id !== id);
    });
    try {
        const { data } = await api.delete(`/users/tokens`, { data: toUrl({ id }) });
        runInAction(() => {
            this.tokens = data;
        });
    } catch (e) {
        failRequest(e);
        runInAction(() => {
            this.tokens = tokens;
        });
    }
}
